@import "src/styles/index";

.formContainer {
  z-index: 1;
  width: 100%;
  max-width: 800px;
  margin-top: $margin-base;

  @include respond-to-tablet {
    max-width: 600px;
  }

  @include respond-to-mobile {
    max-width: 360px;
  }
}

.form {
  max-width: 460px;
  margin-top: 1rem;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }
}

.submitButton {
  width: 170px;
  height: 50px;
  margin: 12px 0 20px 0;
}

.forgotPassword {
  line-height: 25px;
  text-decoration: underline;
  color: $color-text;
}

.generalError {
  margin-top: 2px;
  color: red;
  font-size: 14px;
  line-height: 20px;
}
