@import "src/styles/index";

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  margin: $margin-small;
  transition: height 0.3s ease;

  span:not(:first-child) {
    padding: 0 $padding-base;
  }
}

.date {
  flex: 1;
}

.name {
  flex: 1;
}

.wager {
  flex: 1;
}

.prize {
  flex: 1;
}

.replay {
  flex: 1;
  text-align: right;
}
