@import 'src/styles/index';

.selectContainer {
  @include flex-centered(true);
  align-items: flex-start;
  width: 100%;

  .label {
    @include font-setup($font-family, $font-size-small, $root-font-weight);
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    padding-bottom: $padding-small;
    line-height: 20px;
  }

  .avatarsWrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;

    padding: $padding-small 0 $padding-large;

    column-gap: 6px;
    row-gap: 5px;

    @include respond-to-tablet {
        grid-template-columns: repeat(8, 1fr);
    }
  }
}

.avatar {
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;

  border-radius: 50%;

  overflow: hidden;

  transition: box-shadow 250ms;

  &.selected {
    box-shadow: 0 0 0 3px black;
  }

  img {
    width: 32px;
    height: 32px;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(100px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100px);
    opacity: 0;
  }
}
