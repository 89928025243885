@import "../../styles/index";

.container {
  position: relative;
  width: 100%;
}

.label {
  @include font-setup($font-family, $font-size-small, $root-font-weight);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  padding-bottom: $padding-small;
  line-height: 20px;
}

.inputContainer {
  @include input-container;
}

.input {
  @include input-styles;
  @include font-setup($font-family, $font-size-base, $root-font-weight);
  line-height: 22px;
  z-index: 2;
}

.editIcon {
  position: absolute;
  right: 20px;
  bottom: calc(56px / 2 - 15px / 2);
  display: none;

  pointer-events: none;
}

.input:not(:placeholder-shown, :focus, :active) + .editIcon {
  display: flex;
}

.numberField {
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    opacity: 0;
    appearance: none;
  }
}

.comment {
  font-style: italic;
  margin-top: 0.5rem;
}

.error {
  margin-top: 2px;
  color: red;
  font-size: 14px;
  line-height: 20px;
}
