@import "./styles/utils/index";

.App {
  display: flex;
  flex-direction: column;
  background: $background-primary;
  color: $color-text;
  width: 100%;
  height: 100%;
  min-height: $app-height;

  > div {
    width: 100%;
  }
}

main {
  position: relative;
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  > div {
    width: 100%;
  }
}
