@import 'src/styles/index';

.wrapper {
  @include flex-centered();
  align-items: flex-start;
  position: relative;

  width: auto;
  height: 24px;

  input {
    width: 24px;
    height: 24px;
  }

  .label {
    @include font-setup($font-family, $font-size-base, $font-weight-bold);

    position: relative;
    margin-left: $margin-base;

    pointer-events: none;

    img {
        height: 24px;
    }
  }
}
