@import "src/styles/index";

.dropdown {
  width: 215px;

  .dropbtn {
    font-family: $font-family;
    font-size: $font-size-small !important;
    font-weight: $root-font-weight !important;
    height: 60px !important;
    color: $profile-menu-text-color !important;
    padding: $padding-base !important;
    // background: $profile-menu-background !important;
    // border: $profile-menu-border !important;
    // border-radius: $profile-menu-border-radius !important;
    // backdrop-filter: $profile-menu-blur !important;

    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0) !important;
    border-radius: 0 !important;
    backdrop-filter: none !important;

    z-index: 26 !important;

    .content {
        @include flex-centered();
        letter-spacing: 0;
        justify-content: space-between;
        width: 95%;

      .arrowActive {
        transition: transform 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }

    .balanceWrapper {
      @include flex-centered();
      text-align: left;
      word-break: break-word;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .nicknameContainer {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .balanceLabel {
        text-decoration: underline;
      }
    }

    &.dropbtnActive {
      background: transparent !important;
      border: none !important;
      border-bottom: 1px solid $color-light-grey !important;
      border-radius: 0 !important;
      backdrop-filter: none !important;
    }

    &:before {
      content: "";
      background: transparent;
    }

    &:after {
      content: "";
      background: transparent;
    }

    @include respond-to-mobile {
      width: 100%;
      margin: 0 !important;
      border-radius: 2px 2px 10px 10px !important;
    }
  }

  @include respond-to-mobile {
    width: 100%;
  }
}

.dropdown_content {
  // display: none;
  position: absolute;
  min-width: 160px;
  width: 100%;
  top: 10px;
  left: 8px;
  background: $profile-menu-background;
  border: $profile-menu-border;
  border-radius: $profile-menu-border-radius;
  backdrop-filter: $profile-menu-blur;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 25;
  padding-top: 60px;
  box-sizing: content-box;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  & > * {
    opacity: 0;
    transition: opacity 0.5s 0.2s linear;
  }

  .break {
    width: 100%;
    height: 1px;
    background: $color-light-grey;
    margin: 0;
    padding: 0;
  }

  a {
    @include font-setup($font-family, $font-size-base, $root-font-weight);
    color: $profile-menu-text-color;
    padding: $padding-base 35px;
    text-decoration: underline;
    display: block;
  }

  @include respond-to-mobile {
    top: 0;
    left: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.isVisible {
  @include flex-centered(true);
  align-items: flex-start;

  max-height: 500px;

  & > * {
    opacity: 1;
  }
}
