@import "src/styles/index";

.wrapper {
  background: $footer-background;
  color: $text-color-light;
  width: 100%;
  position: relative;
  bottom: 0;
  transition: height 0.3s ease-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $footer-lights;
    z-index: 1;
  }
}

.container {
  @include flex-centered;
  flex-direction: column;
  transition: height 0.3s ease-out;
}

.contentWrapper {
  @include flex-centered(true);
  position: relative;
  width: 90%;
  padding: 42px 0 0;
  flex-wrap: nowrap;
  transition: height 0.3s ease-out;

  @include respond-to-tablet {
    padding: 48px 0 0;
    flex-wrap: wrap;
  }

  @include respond-to-mobile {
    padding: 48px 0 0;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.groupOne, .groupTwo, .groupThree {
  @include flex-centered(true);
  position: relative;
  width: 100%;
  max-width: 850px;
  margin: 20px 10px;

  h5, p {
    margin: 3px;
  }

  .link {
    text-decoration: underline;
  }

  @include respond-to-tablet {
    width: 80%;
    text-align: left;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  @include respond-to-mobile {
    width: 90%;
    text-align: left;
  }
}

.groupOne {
  margin-bottom: 40px;
}

.groupThree {
  margin-bottom: 0;
}

.groupTwo, .groupThree {
  .aboutBody {
    display: flex;
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: left;

    @include respond-to-tablet {
      width: 100%;
    }

    @include respond-to-mobile {
      width: 90%;
      text-align: left;
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }
}

.copyright {
  @include font-setup($font-family, $font-size-small);
  @include flex-centered;
  position: relative;
  height: 45px;
  bottom: -1px;
  justify-content: space-between;
  width: 100%;
  background-color: $color-black;
  text-align: center;
  transition: height 0.3s ease-out;
  z-index: 1;

  p {
    @include font-setup($font-family, $font-size-small);
    margin: 0 20px;
    line-height: 45px;
  }

  .footerLinks {
    margin: 0 20px;
    @include font-setup($font-family, $font-size-small);

    a {
      color: $text-color-light;
      text-decoration: underline;
      margin: 5px;
    }

    @include respond-to-tablet {
      margin-top: 20px;
    }
  }

  @include respond-to-tablet {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    height: 104px;
    margin: auto;
    text-align: left;
  }
}

