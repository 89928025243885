/* stylelint-disable font-family-name-quotes */
@font-face {
  font-weight: 400;
  font-family: 'DM Sans';
  font-style: normal;
  src: url('../../../public/fonts/DMSans-VariableFont_opsz,wght.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'DM Sans Italic';
  font-style: normal;
  src: url('../../../public/fonts/DMSans-Italic-VariableFont_opsz,wght.ttf') format('truetype');
  font-display: swap;
}
