// responsive breakpoints
@mixin flex-centered($direction-column: false) {
  display: flex;

  align-items: center;
  justify-content: center;

  @if $direction-column {
    flex-direction: column;
  }
}

@mixin respond-to-mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin respond-to-tablet {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin respond-to-desktop {
  @media (max-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin respond-to-large-desktop {
  @media (max-width: $breakpoint-large-desktop) {
    @content;
  }
}

// text mixins
@mixin h1() {
  @include font-setup($font-family, $font-size-heading, $font-weight-bold);
  line-height: 67px;

  @include respond-to-tablet {
    @include font-setup($font-family, $font-size-heading-mobile, $font-weight-bold);
    line-height: 40px;
  }

  @include respond-to-mobile {
    @include font-setup($font-family, $font-size-heading-mobile, $font-weight-bold);
    line-height: 40px;
  }
}

@mixin h2() {
  @include font-setup($font-family, $font-size-heading-medium, $font-weight-bold);
  line-height: 40px;

  @include respond-to-tablet {
    @include font-setup($font-family, $font-size-heading-small, $font-weight-bold);
    line-height: 30px;
  }

  @include respond-to-mobile {
    @include font-setup($font-family, $font-size-heading-small, $font-weight-bold);
    line-height: 30px;
  }
}

@mixin h3() {
  @include font-setup($font-family, $font-size-large, $font-weight-bold);
  line-height: 24px;
}

@mixin h5() {
  @include font-setup($font-family, $font-size-base);
  line-height: 20px;
}

@mixin paragraph() {
  @include font-setup($font-family, $font-size-base);
  line-height: 20px;

  @include respond-to-mobile {
    @include font-setup($font-family, $font-size-small);
    line-height: 18px;
  }

  @include respond-to-tablet {
    @include font-setup($font-family, $font-size-base);
    line-height: 20px;
  }
}

@mixin font-setup($family, $size, $weight: 400) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;

  @if $weight == 700 {
    letter-spacing: -0.03em;
  }
}

// components mixins
@mixin button-styles {
  @include font-setup($font-family, $font-size-base, 700);
  height: 42px;
  color: $button-primary-text-color;
  border-radius: $button-border-radius;
  margin: $margin-base;
  cursor: pointer;
  padding: 0 35px;

  @include respond-to-tablet {
    @include font-setup($font-family, $font-size-base, 700);
    margin: $margin-base;
    padding: 3px 15px;
  }

  @include respond-to-mobile {
    @include font-setup($font-family, $font-size-small, 700);
    margin: $margin-small;
  }
}

@mixin hero-background {
  background-image: $background-hero-image;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;

  @include respond-to-mobile {
    background-attachment: scroll;
  }
}

@mixin logo {
  background-image: $logo-image;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

@mixin header {
  @include flex-centered();
  position: relative;
  height: $header-height-desktop;
  width: 100%;
  justify-content: space-between;
  background: $header-background;
  background-size: cover;
  transition: height 0.3s ease-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height-desktop;
    background: $header-lights;
    z-index: -1;
    transition: height 0.3s ease-out;
    background-repeat: no-repeat;
    animation: moveHeaderLights 10s ease-in-out infinite;


    @include respond-to-tablet {
      height: $header-height-desktop;
    }

    @include respond-to-mobile {
      height: $header-height-mobile;
    }
  }


@keyframes moveHeaderLights {
  50% {
    background-position: -150px 0px, 150px 0px, 180px 0px, -170px 0px;
  }
  0%, 100% {
    background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  }
}

  @include respond-to-tablet {
    height: $header-height-desktop;
  }

  @include respond-to-mobile {
    height: $header-height-mobile;
  }
}

@mixin input-styles {
  @include font-setup($font-family, $font-size-base, 400);
  display: block;
  color: $color-text;
  width: calc(100% - 30px);
  height: 30px;
  padding: $padding-base 15px;
  background: $input-background;
  border: none;
  border-radius: $input-border-radius;

  &::placeholder {
    color: $color-text;
  }

  &:focus,
  &:active {
    outline: none;
    caret-color: $color-text;
  }

  &:disabled {
    opacity: 0.5;
  }

  @include respond-to-mobile {
    font-size: $font-size-small;
  }

  @include respond-to-tablet {
    font-size: $font-size-base;
  }
}

@mixin input-container {
    &::before {
        content: '';
        position: absolute;
        top: 25px;
        left: 0;
        width: 100%;
        height: 50px;
        background: $input-background-gradient;
        z-index: 1;
        pointer-events: none;
    }

    &::after {
        content: '';
        position: absolute;
        top: 73px;
        left: 0;
        width: 100%;
        height: 3px;
        background: $input-default-color;
        z-index: 2;
        pointer-events: none;
    }

    &.hasError::after {
        background: $input-error-color;
    }

    &.hasSuccess::after {
        background: $input-success-color;
    }
}

@mixin cardBadge {
  @include flex-centered();
  @include font-setup($font-family, $font-size-small, $root-font-weight);
  position: absolute;
  background: $background-secondary;
  color: $button-primary-text-color;
  height: 25px;
  // min-width: 55px;
  padding: $padding-small 8px;
  border-radius: 15px;
  z-index: 1;
}
