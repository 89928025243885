@import "src/styles/index";

.formContainer {
  z-index: 1;
  width: 100%;
  max-width: 800px;
  margin-top: $margin-base;

  .subheading {
    margin-top: 1rem;
  }

  @include respond-to-tablet {
    max-width: 600px;
  }

  @include respond-to-mobile {
    max-width: 360px;
  }
}

.form {
  max-width: 420px;
  margin-top: 1rem;

  .label {
    text-transform: uppercase;
  }

  .payment {
    @include flex-centered();
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: $margin-large;
    margin-top: $margin-large;
  }

  .depositContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 330px;

    input {
      flex: 0.7;
    }

    button {
      flex: 0.3;
    }
  }

  .comment {
    font-style: italic;
    margin-top: 1rem;
  }
}

.submitButton {
  width: 170px;
  height: 50px;
  margin: 0 $margin-base;
}

.generalError {
  margin-top: 2px;
  color: red;
  font-size: 14px;
  line-height: 20px;
}
