
// Colors
$color-black: #000;
$color-white: #fff;
$color-dark: #222222;
$text-color-light: $color-white;
$input-success-color: #18A353;
$input-error-color: #D1191D;
$input-default-color: $color-dark;
$color-primary: #66F1C3;
$color-secondary: #6c757d;
$color-light-grey: #FFFFFF1A;
$color-text: $color-dark;
$background-grey: #393939;

// Shadows
$box-shadow-green: 0px 0px 54px 0px #C8E21D66;
$box-shadow-purple: 0px 0px 54px 0px #E77FE180;
$box-shadow-blue: 0px 0px 54px 0px #2A77EB99;
$box-shadow-greenblue: 0px 0px 54px 0px #57DC3F66;

// Font sizes
$font-family: 'DM Sans', sans-serif;
$font-size-small: 14px;
$font-size-base: 16px;
$font-size-large: 18px;
$font-size-heading-small: 24px;
$font-size-heading-medium: 32px;
$font-size-heading-mobile: 40px;
$font-size-heading: 60px;
$root-font-weight: 400;
$font-weight-bold: 700;

// Fonts
$font-family: 'DM Sans', sans-serif;
$font-family-secondary: 'Georgia, serif';

// Backgrounds
$background-primary: radial-gradient(13.59% 69.06% at 8.96% 65.8%, rgba(93, 36, 226, 0.12) 0%, rgba(93, 36, 226, 0) 100%),
radial-gradient(12.5% 56.46% at 96.88% 11.07%, rgba(21, 253, 90, 0.16) 0%, rgba(21, 253, 90, 0) 100%);

$body-background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
$background-secondary: #ffffff;
$background-hero-image: url('../../assets/images/hero.jpg');
$background-bingo-block: $background-grey;

// Header
$header-height-mobile: 80px;
$header-height-desktop: 150px;
$header-background: linear-gradient(90deg, #1A2927 0%, #1A3E62 100%);
$header-lights: radial-gradient(12.08% 114.58% at 23.75% 42.67%, rgba(224, 48, 230, 0.4) 0%, rgba(224, 48, 230, 0) 100%),
radial-gradient(8.4% 60.67% at 73.13% 39.33%, rgba(44, 144, 238, 0.5) 0%, rgba(44, 144, 238, 0) 100%),
radial-gradient(18.96% 175.1% at 37.92% 27.33%, rgba(41, 209, 75, 0.3) 0%, rgba(41, 209, 75, 0) 100%),
radial-gradient(7.22% 72.67% at 89.38% 27.33%, rgba(249, 32, 32, 0.4) 0%, rgba(249, 32, 32, 0) 100%);
$profile-menu-background: radial-gradient(50% 50% at 50% 50%, rgba(145, 145, 145, 0.497) 0%, rgba(145, 145, 145, 0.71) 100%),
radial-gradient(57.8% 47.29% at 50% 94.19%, rgba(35, 35, 35, 0.41) 0%, rgba(43, 43, 43, 0) 100%),
radial-gradient(83.49% 68.31% at 14.22% 3.32%, rgba(147, 147, 147, 0.16) 0%, rgba(139, 139, 139, 0) 100%);
$profile-menu-blur: blur(8px);
$profile-menu-border: 2px solid $color-light-grey;
$profile-menu-border-radius: 29px;
$profile-menu-text-color: $text-color-light;

// Footer
$footer-background: linear-gradient(180deg, #4F4F4F 0%, #0A0A0A 100%);
$footer-lights: radial-gradient(19.99% 79.58% at 24.72% 87.09%, rgba(78, 238, 38, 0.16) 0%, rgba(78, 238, 38, 0) 100%),
radial-gradient(20.42% 83.53% at 63.47% 50.15%, rgba(100, 73, 233, 0.23) 0%, rgba(100, 73, 233, 0) 100%),
radial-gradient(14.58% 59.64% at 94.51% 53.45%, rgba(65, 208, 227, 0.29) 0%, rgba(65, 208, 227, 0) 100%),
radial-gradient(22.92% 91.06% at 43.26% 94.89%, rgba(225, 43, 104, 0.2) 0%, rgba(225, 43, 104, 0) 100%);

// Buttons
$button-background: linear-gradient(180deg, #63FFCE 0%, #6BD3B3 100%);
$button-background-secondary: linear-gradient(0deg, #FFFFFF, #FFFFFF);
$button-gradient: radial-gradient(33.83% 69.96% at 16.54% 50%, rgba(85, 238, 141, 0.8) 0%, rgba(85, 238, 141, 0) 100%),
radial-gradient(21.8% 77.33% at 78.2% 50%, rgba(154, 216, 244, 0.7) 0%, rgba(154, 216, 244, 0) 100%);
$button-gradient-secondary: radial-gradient(23.31% 63.97% at 15.79% 38.1%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
radial-gradient(38.35% 104.79% at 75.19% 66.67%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);

$button-hover-background: darken($color-primary, 10%);
$button-primary-text-color: $color-dark;
$button-secondary-text-color: $text-color-light;
$button-secondary-border-color: $color-white;
$button-border-radius: 5px;
$primary-button-border: 2px solid #FFFFFF66;
$primary-button-border-hover: #FFFFFFFF;
$secondary-button-border-hover: #dddddd;

// Inputs
$input-border-radius: 4px;
$input-background: linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 68%);
$input-background-gradient: radial-gradient(26.3% 234.67% at 84.35% 50%, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%),
radial-gradient(19.35% 140.72% at 2.17% 64%, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);

// Spacing
$padding-small: 5px;
$padding-base: 10px;
$padding-large: 20px;
$margin-small: 5px;
$margin-base: 10px;
$margin-large: 20px;

// Logo
$logo-image: url('../../assets/images/logo.png');

// Breakpoints
$breakpoint-mobile: 510px;
$breakpoint-tablet: 860px;
$breakpoint-desktop: 1024px;
$breakpoint-large-desktop: 1440px;
$app-height: var(--inner-height);
