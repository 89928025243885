@import "src/styles/index";

.menuContainer {
  @include flex-centered();
  z-index: 1;
  width: 100%;
  background: $footer-background;

  .menu {
    @include flex-centered();
    @include font-setup($font-family, $font-size-small, $font-weight-bold);
    color: $text-color-light;
    width: 100%;
    max-width: 800px;
    margin: $margin-base;
    z-index: 2;
  }

  .menuItem {
    text-decoration: none;
    list-style: none;
    margin: 0 15px;
    cursor: pointer;
    z-index: 3;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      text-decoration: underline;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $footer-lights;
    z-index: 1;
  }
}
