@import "src/styles/index";

.heroContainer {
  @include font-setup($font-family, $font-size-large, 400);
  @include hero-background;
  @include flex-centered(true);
  width: 100%;
  height: 600px;
  color: $text-color-light;

  .content {
    text-align: left;
    position: relative;
    z-index: 1;
    left: 20%;
    top: -5%;

    .heading {
      max-width: 460px;
    }

    .subheading {
      margin: 0;
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
    }

    @include respond-to-tablet {
      left: 10%;
      top: -5%;
      width: 80%;
    }

    @include respond-to-mobile {
      top: -10%;
      left: 0;
      width: 70%;
    }
  }

  .buttonContainer {
    @include flex-centered();
    justify-content: space-between;
    margin-top: 20px;
    max-width: 80%;

    button {
      margin: 0;
    }

    @include respond-to-tablet {
      max-width: 100%;
    }
  }
}

