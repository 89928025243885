@import "./styles/utils/index";

html {
  box-sizing: border-box;
}

body {
  background: $body-background;
  color: $color-text;
  min-height: unset !important;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

#header, main, footer {
  @include font-setup($font-family, $font-size-base);

  @include respond-to-mobile {
    @include font-setup($font-family, $font-size-small);
  }
}

img {
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

#header button {
  @include button-styles;

  &:focus {
    outline: none;
  }
}

main button {
  @include button-styles;

  &:focus {
    outline: none;
  }
}

.hero {
  @include hero-background;
  height: 500px;
}

input {
  width: 100%;
  padding: 10px;
  box-sizing: revert;
  font-size: var(--font-size-base);
  border-radius: var(--input-border-radius);

  @include respond-to-mobile {
    font-size: var(--font-size-small);
    padding: 8px;
  }

  @include respond-to-tablet {
    font-size: var(--font-size-base);
    padding: 12px;
  }
}
