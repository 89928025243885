@import "src/styles/index";

.formContainer {
  z-index: 1;
  width: 100%;
  max-width: 800px;
  margin-top: $margin-base;

  .subheading {
    margin-top: 1rem;
  }

  @include respond-to-tablet {
    max-width: 600px;
  }

  @include respond-to-mobile {
    max-width: 360px;
  }
}

.form {
  max-width: 460px;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }

  .zipCityContainer {
    display: flex;
    gap: 12px;

    .zipinput {
      flex: 1 1 33%;
    }

    .cityinput {
      flex: 1 1 67%;
    }
  }

  .nicknameContainer {
    display: flex;

    .nicknameinput {
      width: 50%;
    }
  }
}

.submitButton {
  padding: 0 15px 3px;
  letter-spacing: -1px;
  width: 180px;
  height: 50px;
  margin: $margin-large 0 $margin-large 0;
}

.forgotPassword {
  line-height: 25px;
  text-decoration: underline;
  color: $color-text;
}

.generalError {
  margin-top: 2px;
  color: red;
  font-size: 14px;
  line-height: 20px;
}

.consent {
  @include flex-centered();
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  > input {
    width: 15px;
    margin-top: 2px;
    margin-right: 10px;
    cursor: pointer;
  }
}
