@import "src/styles/index";

.container {
  z-index: 1;
  width: 100%;
  max-width: 800px;
  margin-top: $margin-base;

  .table {
    position: relative;
    width: 100%;
    padding-top: $padding-base;

    th {
      text-align: left;
      border-bottom: 1px solid black;
    }

    tr {
      width: 100%;
    }
  }

  .tableHeader {
    @include font-setup($font-family, $font-size-base, $font-weight-bold);
    flex: 1;
    padding: $padding-base $padding-small;

    @include respond-to-mobile {
      height: 60px;
      padding: $padding-base;
    }
  }

  .tableData {
    @include font-setup($font-family, $font-size-base, $root-font-weight);
    width: 100%;
    padding: 0;
    overflow: hidden;

    @include respond-to-mobile {
      @include font-setup($font-family, $font-size-small, $root-font-weight);
    }
  }

  .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: $margin-base;
  }

  .tableHeader:nth-of-type(5) {
    flex: 1;
    color: transparent;
  }

  .noData {
    @include flex-centered();
    height: 100px;
    margin: $margin-large 0;
  }

  .paginationContainer {
    @include flex-centered();
    border-top: 1px solid black;
    justify-content: space-between;
    margin: 0 0 $margin-large 0;

    button {
      max-width: 150px;
      margin: $margin-base 0;

      @include respond-to-mobile {
        max-width: 100px;
      }
    }

    p {
      margin: 0 10px;
    }
  }

  @include respond-to-tablet {
    max-width: 600px;
  }

  @include respond-to-mobile {
    max-width: 360px;
  }
}
