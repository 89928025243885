@import "src/styles/index";

$maxBlockContainerWidth: 400px*3 + 20px*2;

.blockContainer {
    @include flex-centered(true);
    background: $background-bingo-block;

    .titleContainer {
        width: 100%;
        max-width: $maxBlockContainerWidth;
        display: flex;
        align-items: flex-start;

        .title {
            color: $text-color-light;
            margin-top: $margin-large;

            @media screen and (max-width: ($maxBlockContainerWidth + 40px)) {
                width: 100%;
                text-align: center;
            }
        }
    }

    .content {
        @include flex-centered();
        width: 100%;
        max-width: $maxBlockContainerWidth;
        flex-wrap: wrap;
        gap: 20px;
        margin: 1.5rem 0;

        @include respond-to-mobile {
            margin: 0;
        }
    }
}
