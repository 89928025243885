@import "../../styles/index";

.wrapper {
  @include flex-centered();
  position: relative;
  width: 100%;
  transition: opacity 0.2s ease-in, color 0.2s, background-color 0.2s, background 0.2s;

  .childrenWrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    @include respond-to-tablet {
      margin: 0;
    }
  }
}

.background {
  @include flex-centered;

  inset: 0;
  transition: opacity 0.2s ease-in, color 0.2s, background-color 0.2s;

  &.hasBlendMode {
    mix-blend-mode: multiply;
  }
}

.primary {
  background: $button-background;
  border: $primary-button-border;
  transition: all 0.25s linear;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $button-gradient;
    background-repeat: no-repeat;
    z-index: 0;
    transition: background-position 0.25s ease-in-out, opacity 0.2s ease-in, color 0.2s, background-color 0.2s;
  }

  &:hover {
    border-color: $primary-button-border-hover;

    &:before {
      background-position: -20px 0px, 20px 0px;
    }
  }
}

.primaryBig {
  height: 52px;
  background: $button-background;
  border: $primary-button-border;
  transition: all 0.25s linear;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $button-gradient;
    background-repeat: no-repeat;
    z-index: 0;
    transition: background-position 0.25s ease-in-out, opacity 0.2s ease-in, color 0.2s, background-color 0.2s;
  }

  &:hover {
    border-color: $primary-button-border-hover;

    &:before {
      background-position: -50px 0px, 50px 0px;
    }
  }
}

.secondary {
  background: $button-background-secondary;
  border: 3px solid #FFFFFF;
  transition: all 0.25s linear;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $button-gradient-secondary;
    background-repeat: no-repeat;
    z-index: 0;
    transition: background-position 0.25s ease-in-out, opacity 0.2s ease-in, color 0.2s, background-color 0.2s;
  }

  &:hover {
    border-color: $secondary-button-border-hover;

    &:before {
      background-position: -20px 0px, 20px 0px;
    }
  }
}

.tertiary {
  background: transparent;
  border: none;
  color: $button-secondary-text-color;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.disabled {

}

