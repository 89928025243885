@import "src/styles/index";

.wrapper {
  @include header;
  z-index: 15;
  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }
}

.logoContainer {
  @include flex-centered;
  position: absolute;
  top: 35%;
  left: 50%;
  height: 42px;
  cursor: pointer;

  transform: translateX(-50%);

  @include respond-to-mobile {
    top: 40%;
  }
}

.logo {
  @include logo;
  position: relative;
  width: 196px;
  object-fit: contain;
  transition: width 0.3s ease-out;

  @include respond-to-mobile {
    width: 140px;
  }
}

.buttonContainer {
    @include flex-centered;
    position: absolute;
    right: 2rem;

    @include respond-to-tablet {
        right: 1rem;
    }
}

.menuContainer {
    @include flex-centered;
    position: absolute;
    right: 2rem;

    @include respond-to-tablet {
        right: 1rem;
    }

    @include respond-to-mobile {
        top: 75px;
        right: 0;
        left: 0;
        margin: 0;
        width: 100%;
    }

  &.hideOnMobile {
    @include respond-to-mobile {
      display: none;
    }
  }
}
