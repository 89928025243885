@import "src/styles/index";

.textBlock {
  max-width: 800px;
  word-break: break-word;

  @include respond-to-tablet {
    max-width: 90%;
  }

  @include respond-to-mobile {
    max-width: 85%;
  }

  &.specialStyles {
    @include respond-to-tablet {
      margin-top: 3rem !important;
    }

    @include respond-to-mobile {
      margin-top: 5rem !important;
    }
  }
}
