@import "src/styles/index";

.wrapper {
  position: relative;
  width: 400px;
  height: 250px;
  z-index: 10;
  border-radius: 5px;
  overflow: hidden;

  .container {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.15s ease-in;
    }

    .image.loaded {
      opacity: 1;
    }

    .nameBadge {
      @include cardBadge;
      top: 10px;
      left: 10px;
      height: 35px;
      padding: $padding-base 15px;
      border-radius: 25px;

      background: $button-background-secondary;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $button-gradient-secondary;
        z-index: 0;
        transition: opacity 0.2s ease-in, color 0.2s, background-color 0.2s;
      }

      .roomName {
        font-weight: 700;
        letter-spacing: -0.03em;
      }
    }

    .playersBadge {
      @include cardBadge;
      padding-left: 2px;
      top: 10px;
      right: 10px;
    }

    .timeBadge {
      @include cardBadge;
      padding-left: 2px;
      top: 45px;
      right: 10px;
    }

    .priceBadge {
      @include cardBadge;
      top: 80px;
      right: 10px;
    }

    .bottomContainer {
      @include flex-centered();
      justify-content: space-between;
      padding: 0 30px 0 24px;
      background: #FFFFFFB2;
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75px;

      .heading {
        text-align: left;
        margin: 0;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 22px;
      }

      .textContainer {
        min-width: 130px;
      }

      .gameName {
        margin: 0;
        font-size: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .joinButton {
        padding: 5px 10px;
      }

    }
  }

  &:nth-child(4n + 1) {
    box-shadow: $box-shadow-green;
  }
  &:nth-child(4n + 2) {
    box-shadow: $box-shadow-purple;
  }
  &:nth-child(4n + 3) {
    box-shadow: $box-shadow-blue;
  }
  &:nth-child(4n + 4) {
    box-shadow: $box-shadow-greenblue;
  }

  @include respond-to-mobile {
    width: 90%;
    height: 250px;

    &:first-child {
      margin-top: 30px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
}
